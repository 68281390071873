import React from 'react'

export const SiteIcon = ({ width = 16, height = 16, className = '', active = false }) =>
  active ? (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M27.1518 15.9398C27.1755 15.6296 27.1875 15.3162 27.1875 15C27.1875 13.0948 26.7503 11.2917 25.9708 9.68556C24.4245 11.1535 22.5839 12.3144 20.5467 13.0708C20.5986 13.707 20.625 14.3504 20.625 15C20.625 16.3765 20.5063 17.7253 20.2787 19.0367C22.7703 18.4097 25.0897 17.3491 27.1518 15.9398Z"
            fill="currentColor"
          />
          <path
            d="M18.2929 19.4377C18.5925 18.0055 18.75 16.5212 18.75 15C18.75 14.5396 18.7356 14.0824 18.7071 13.6291C17.5176 13.9125 16.2763 14.0625 15 14.0625C13.7237 14.0625 12.4824 13.9125 11.2929 13.6291C11.2644 14.0824 11.25 14.5396 11.25 15C11.25 16.5212 11.4075 18.0055 11.7071 19.4377C12.7807 19.6022 13.8804 19.6875 15 19.6875C16.1196 19.6875 17.2193 19.6022 18.2929 19.4377Z"
            fill="currentColor"
          />
          <path
            d="M12.2153 21.3988C13.1285 21.5069 14.0577 21.5625 15 21.5625C15.9423 21.5625 16.8715 21.5069 17.7847 21.3988C17.1425 23.4684 16.1969 25.4045 15 27.1551C13.8031 25.4045 12.8575 23.4684 12.2153 21.3988Z"
            fill="currentColor"
          />
          <path
            d="M9.72132 19.0367C9.49366 17.7253 9.375 16.3765 9.375 15C9.375 14.3504 9.40143 13.707 9.45327 13.0708C7.41607 12.3144 5.57549 11.1535 4.02916 9.68556C3.24967 11.2917 2.8125 13.0948 2.8125 15C2.8125 15.3162 2.82454 15.6296 2.84819 15.9398C4.91032 17.3491 7.22967 18.4097 9.72132 19.0367Z"
            fill="currentColor"
          />
          <path
            d="M26.6951 18.4407C25.4255 22.7632 21.8247 26.0883 17.347 26.9618C18.43 25.1405 19.2732 23.1599 19.8331 21.0637C22.2829 20.5501 24.592 19.654 26.6951 18.4407Z"
            fill="currentColor"
          />
          <path
            d="M3.30494 18.4407C5.40803 19.654 7.71715 20.5501 10.1669 21.0637C10.7268 23.1599 11.57 25.1405 12.653 26.9618C8.17534 26.0883 4.57455 22.7632 3.30494 18.4407Z"
            fill="currentColor"
          />
          <path
            d="M17.347 3.03832C20.4989 3.65318 23.2164 5.48287 24.9921 8.02011C23.6689 9.35618 22.0796 10.4283 20.3104 11.1503C19.8276 8.22865 18.8044 5.48928 17.347 3.03832Z"
            fill="currentColor"
          />
          <path
            d="M15 2.84497C16.7858 5.45697 18.0121 8.48183 18.5062 11.7469C17.3854 12.0346 16.2106 12.1875 15 12.1875C13.7894 12.1875 12.6146 12.0346 11.4938 11.7469C11.9879 8.48183 13.2142 5.45697 15 2.84497Z"
            fill="currentColor"
          />
          <path
            d="M12.653 3.03831C11.1956 5.48927 10.1724 8.22865 9.68964 11.1503C7.92036 10.4283 6.33111 9.35617 5.00793 8.02011C6.78361 5.48287 9.50106 3.65318 12.653 3.03831Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15 26.25C20.2407 26.25 24.6445 22.6665 25.8946 17.8162M15 26.25C9.75928 26.25 5.35552 22.6665 4.10537 17.8162M15 26.25C18.1066 26.25 20.625 21.2132 20.625 15C20.625 8.7868 18.1066 3.75 15 3.75M15 26.25C11.8934 26.25 9.375 21.2132 9.375 15C9.375 8.7868 11.8934 3.75 15 3.75M15 3.75C19.2065 3.75 22.8738 6.05867 24.8038 9.47806M15 3.75C10.7935 3.75 7.12624 6.05867 5.19616 9.47806M24.8038 9.47806C22.1747 11.7505 18.7478 13.125 15 13.125C11.2522 13.125 7.82534 11.7505 5.19616 9.47806M24.8038 9.47806C25.7246 11.1093 26.25 12.9933 26.25 15C26.25 15.9725 26.1266 16.9161 25.8946 17.8162M25.8946 17.8162C22.6667 19.6059 18.9524 20.625 15 20.625C11.0476 20.625 7.33328 19.6059 4.10537 17.8162M4.10537 17.8162C3.87339 16.9161 3.75 15.9725 3.75 15C3.75 12.9933 4.27541 11.1093 5.19616 9.47806"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
