import React from 'react'

export const AdsIcon = ({ width = 16, height = 16, className = '', active = false }) =>
  active ? (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M21.1015 5.43178C18.5125 6.4675 15.7397 7.14149 12.845 7.39126C12.0106 7.46325 11.166 7.50001 10.3125 7.50001H9.375C5.75063 7.50001 2.8125 10.4381 2.8125 14.0625C2.8125 17.312 5.17431 20.0099 8.27496 20.5332C8.6928 22.2445 9.27257 23.8922 9.99706 25.4593C10.5771 26.714 12.0898 27.1093 13.2254 26.4537L14.0458 25.98C15.1459 25.3449 15.5028 23.9892 15.0108 22.8949C14.7173 22.2419 14.4553 21.5717 14.2268 20.8862C16.6235 21.2088 18.9273 21.8235 21.1015 22.6932C22.0086 19.9815 22.5 17.0794 22.5 14.0625C22.5 11.0456 22.0086 8.14346 21.1015 5.43178Z"
            fill="#0D6EFD"
          />
          <path
            d="M22.8258 4.67589C23.8301 7.62051 24.375 10.7779 24.375 14.0625C24.375 17.3471 23.8301 20.5045 22.8258 23.4491C22.7568 23.6513 22.6859 23.8526 22.6127 24.0528C22.4347 24.539 22.6846 25.0774 23.1708 25.2554C23.657 25.4334 24.1954 25.1835 24.3734 24.6973C24.5484 24.2191 24.712 23.7355 24.8637 23.2466C25.5183 21.1382 25.9534 18.9336 26.1425 16.6597C26.7887 15.9866 27.1875 15.0708 27.1875 14.0625C27.1875 13.0542 26.7887 12.1384 26.1425 11.4653C25.9534 9.19147 25.5183 6.98684 24.8637 4.87841C24.712 4.38955 24.5484 3.90589 24.3734 3.42775C24.1954 2.94154 23.657 2.69166 23.1708 2.86964C22.6846 3.04762 22.4347 3.58605 22.6127 4.07227C22.6859 4.27242 22.7568 4.4737 22.8258 4.67589Z"
            fill="#0D6EFD"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9255 19.7997C12.0644 19.7254 11.1929 19.6875 10.3125 19.6875H9.375C6.2684 19.6875 3.75 17.1691 3.75 14.0625C3.75 10.9559 6.2684 8.4375 9.375 8.4375H10.3125C11.1929 8.4375 12.0644 8.39958 12.9255 8.32527M12.9255 19.7997C13.2416 21.0018 13.6547 22.1647 14.1558 23.2793C14.4651 23.9673 14.2303 24.791 13.577 25.1681L12.7566 25.6418C12.0674 26.0397 11.182 25.7882 10.848 25.0658C10.0647 23.3717 9.45676 21.5799 9.04732 19.7137M12.9255 19.7997C12.444 17.9681 12.1875 16.0453 12.1875 14.0625C12.1875 12.0797 12.444 10.1569 12.9255 8.32527M12.9255 19.7997C16.875 20.1405 20.6056 21.2465 23.9684 22.9686M12.9255 8.32527C16.875 7.9845 20.6056 6.87854 23.9684 5.15636M23.9684 5.15636C23.8213 4.68243 23.6627 4.21354 23.493 3.75M23.9684 5.15636C24.6371 7.31034 25.0697 9.56828 25.2357 11.8999M23.9684 22.9686C23.8213 23.4426 23.6627 23.9115 23.493 24.375M23.9684 22.9686C24.6371 20.8147 25.0697 18.5567 25.2357 16.2251M25.2357 11.8999C25.8555 12.4158 26.25 13.1931 26.25 14.0625C26.25 14.9319 25.8555 15.7092 25.2357 16.2251M25.2357 11.8999C25.2866 12.6141 25.3125 13.3353 25.3125 14.0625C25.3125 14.7897 25.2866 15.5109 25.2357 16.2251"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
