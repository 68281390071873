import React from 'react'

export const SupportIcon = ({ width = 16, height = 16, className = '', active = false }) =>
  active ? (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00456 27.0553C6.22413 27.0946 6.44665 27.1254 6.67113 27.1473C6.94396 27.1739 7.22059 27.1875 7.5 27.1875C9.14412 27.1875 10.6806 26.7163 11.979 25.9016C12.9461 26.1293 13.9585 26.25 15 26.25C21.6525 26.25 27.1875 21.2883 27.1875 15C27.1875 8.71168 21.6525 3.75 15 3.75C8.34749 3.75 2.8125 8.71168 2.8125 15C2.8125 18.0107 4.09325 20.7339 6.15464 22.7404C6.44464 23.0227 6.50134 23.2746 6.47277 23.4186C6.31568 24.2106 5.95859 24.9314 5.45446 25.5264C5.2377 25.7823 5.17408 26.1344 5.2876 26.4499C5.40112 26.7654 5.6745 26.9962 6.00456 27.0553ZM10.3125 13.5938C9.53585 13.5938 8.90625 14.2233 8.90625 15C8.90625 15.7767 9.53585 16.4062 10.3125 16.4062C11.0892 16.4062 11.7188 15.7767 11.7188 15C11.7188 14.2233 11.0892 13.5938 10.3125 13.5938ZM13.5938 15C13.5938 14.2233 14.2233 13.5938 15 13.5938C15.7767 13.5938 16.4062 14.2233 16.4062 15C16.4062 15.7767 15.7767 16.4062 15 16.4062C14.2233 16.4062 13.5938 15.7767 13.5938 15ZM19.6875 13.5938C18.9109 13.5938 18.2812 14.2233 18.2812 15C18.2812 15.7767 18.9109 16.4062 19.6875 16.4062C20.4641 16.4062 21.0938 15.7767 21.0938 15C21.0938 14.2233 20.4641 13.5938 19.6875 13.5938Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M10.7812 15C10.7812 15.2589 10.5714 15.4688 10.3125 15.4688C10.0536 15.4688 9.84375 15.2589 9.84375 15C9.84375 14.7411 10.0536 14.5312 10.3125 14.5312C10.5714 14.5312 10.7812 14.7411 10.7812 15ZM10.7812 15H10.3125M15.4688 15C15.4688 15.2589 15.2589 15.4688 15 15.4688C14.7411 15.4688 14.5312 15.2589 14.5312 15C14.5312 14.7411 14.7411 14.5312 15 14.5312C15.2589 14.5312 15.4688 14.7411 15.4688 15ZM15.4688 15H15M20.1562 15C20.1562 15.2589 19.9464 15.4688 19.6875 15.4688C19.4286 15.4688 19.2188 15.2589 19.2188 15C19.2188 14.7411 19.4286 14.5312 19.6875 14.5312C19.9464 14.5312 20.1562 14.7411 20.1562 15ZM20.1562 15H19.6875M26.25 15C26.25 20.6954 21.2132 25.3125 15 25.3125C13.8907 25.3125 12.8188 25.1653 11.8065 24.8911C10.5877 25.7473 9.10251 26.25 7.5 26.25C7.25098 26.25 7.0047 26.2379 6.76208 26.2142C6.5625 26.1947 6.36475 26.1674 6.16974 26.1325C6.77415 25.4191 7.20346 24.5533 7.39236 23.601C7.50567 23.0297 7.22588 22.4749 6.80855 22.0686C4.91213 20.2227 3.75 17.7358 3.75 15C3.75 9.30456 8.7868 4.6875 15 4.6875C21.2132 4.6875 26.25 9.30456 26.25 15Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
