import React from 'react'

export const HomeIcon = ({ width = 16, height = 16, className = '', active = false }) =>
  active ? (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M14.3371 4.8012C14.7032 4.43508 15.2968 4.43508 15.6629 4.8012L26.5246 15.6629C26.8907 16.029 27.4843 16.029 27.8504 15.6629C28.2165 15.2968 28.2165 14.7032 27.8504 14.337L16.9887 3.47537C15.8904 2.37702 14.1096 2.37703 13.0113 3.47537L2.14959 14.337C1.78347 14.7032 1.78347 15.2968 2.14959 15.6629C2.5157 16.029 3.1093 16.029 3.47541 15.6629L14.3371 4.8012Z"
            fill="currentColor"
          />
          <path
            d="M15 6.78994L25.1988 16.9887C25.2359 17.0258 25.2738 17.0617 25.3125 17.0964V24.8437C25.3125 26.1381 24.2632 27.1875 22.9688 27.1875H18.75C18.2322 27.1875 17.8125 26.7677 17.8125 26.25V20.625C17.8125 20.1072 17.3928 19.6875 16.875 19.6875H13.125C12.6072 19.6875 12.1875 20.1072 12.1875 20.625V26.25C12.1875 26.7677 11.7678 27.1875 11.25 27.1875H7.03125C5.73683 27.1875 4.6875 26.1381 4.6875 24.8437V17.0964C4.72616 17.0617 4.76409 17.0258 4.80124 16.9887L15 6.78994Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.8125 14.9995L14.0056 3.80641C14.5548 3.25724 15.4452 3.25724 15.9944 3.80641L27.1875 14.9995M5.625 12.187V24.8433C5.625 25.6199 6.2546 26.2495 7.03125 26.2495H12.1875V20.1558C12.1875 19.3791 12.8171 18.7495 13.5938 18.7495H16.4062C17.1829 18.7495 17.8125 19.3791 17.8125 20.1558V26.2495H22.9688C23.7454 26.2495 24.375 25.6199 24.375 24.8433V12.187M10.3125 26.2495H20.625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
