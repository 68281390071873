import React, { createContext, useState, useEffect } from 'react'

export const ModalQueueContext = createContext<{
  enqueueModal: (id: string) => void
  dequeueModal: (id: string) => void
  currentModalId: string | undefined
}>({
  enqueueModal: () => {},
  dequeueModal: () => {},
  currentModalId: undefined
})
ModalQueueContext.displayName = 'ModalQueueContext'

export const ModalQueueProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalQueue, setModalQueue] = useState<string[]>([])
  const [currentModalId, setCurrentModalId] = useState<string | undefined>(undefined)

  const enqueueModal = (id: string) => {
    setModalQueue(prevQueue => (prevQueue.includes(id) ? prevQueue : [...prevQueue, id]))
  }

  const dequeueModal = (id: string) => {
    setModalQueue(prevQueue => prevQueue.filter(modalId => modalId !== id))
  }

  useEffect(() => {
    setCurrentModalId(modalQueue[0])
  }, [modalQueue])

  return (
    <ModalQueueContext.Provider
      value={{
        enqueueModal,
        dequeueModal,
        currentModalId
      }}
    >
      {children}
    </ModalQueueContext.Provider>
  )
}
